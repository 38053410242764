import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Redirect, withRouter } from "react-router-dom";
import { ServicesProvider, AuthProvider, UserProvider, EventsProvider, ReportProvider, UsersProvider, BillingProvider } from "./contexts";
import { Login, Home, Arbs, Register, Check, UserHome, ActivateAccount, Extension, Events, ArbsPre, BotHome, UserBets, UserBookies, AddBookie, AcademyHome, AvailableBookies, BotLanding, Terms, ValuebetsPre, Invoices, Pricing, Rewards, BotStats } from "./components";

const App = () => {
  return (
    <Router>
      <ServicesProvider>
        <AuthProvider>
          <UserProvider>
            <EventsProvider>
              <ReportProvider>
                <UsersProvider>
                  <BillingProvider>
                    <Switch>
                      <Route path="/" exact component={withRouter(Home)} />
                      <Route path="/login" exact component={withRouter(Login)} />
                      <Route path="/home" exact component={withRouter(Home)} />
                      <Route path="/home/logged" exact component={withRouter(UserHome)} />
                      <Route path="/home/bot" exact component={withRouter(BotHome)} />
                      <Route path="/home/bot/stats" exact component={withRouter(BotStats)} />
                      <Route path="/bot" exact component={withRouter(BotLanding)} />
                      <Route path="/arbs/live" component={withRouter(Arbs)} />
                      <Route path="/arbspre" component={withRouter(ArbsPre)} />
                      <Route path="/arbs/pre" component={withRouter(ArbsPre)} />
                      <Route path="/arbs/bookies" component={withRouter(AvailableBookies)} />
                      <Route path="/pricing" component={withRouter(Pricing)} />
                      <Route path="/arbs" component={withRouter(Arbs)} />
                      <Route path="/valuebets" component={withRouter(ValuebetsPre)} />
                      <Route path="/register" component={withRouter(Register)} />
                      <Route path="/extension" component={withRouter(Extension)} />
                      <Route path="/events" component={withRouter(Events)} />
                      <Route path="/login" component={withRouter(Login)} />
                      <Route path="/check" component={withRouter(Check)} />
                      <Route path="/activateaccount" exact component={withRouter(ActivateAccount)} />
                      <Route path="/:email/bets" component={withRouter(UserBets)} />
                      <Route exact path="/bookies/:email" component={withRouter(UserBookies)}/>
                      <Route exact path="/bookies/:email/:bookie/:action" component={withRouter(AddBookie)} />
                      <Route exact path="/academy" component={withRouter(AcademyHome)} />
                      <Route exact path="/terms" component={withRouter(Terms)} />
                      <Route exact path="/invoices" component={withRouter(Invoices)} />
                      <Route exact path="/rewards" component={withRouter(Rewards)} />
                      <Route render={() => <Redirect to="/" />} />
                    </Switch>
                  </BillingProvider>
                </UsersProvider>
              </ReportProvider>
            </EventsProvider>
          </UserProvider>
        </AuthProvider>
      </ServicesProvider>
    </Router>
  );
};

export default App;
